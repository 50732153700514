@import '../variables';
@import '../mixins';
.media-boxes{
  .mr-10{
    margin-right: 10px;
  }
  .media-logo{
    height: 30px;
    margin-right: 50px;
  }
  input{
    @include input-standard;
    width: 220px;
  }
  .smallTitle{
    @include title-light-small;
  }
  .sec-title{
    @include title-level-1;
    margin-top: 20px;
    .share-settings{
      color: #436FFD;
      text-decoration: underline;
      margin-left: 10px;
      display: inline;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .buttons-container{
    display: flex;
    align-items: center;
    button{
      margin-right:10px;
      @include buttonTransparent;
      white-space: nowrap;
      &.active{
        @include buttonBlueTransparent;
      }
    }
  }
}
.shareSettings{
  display: flex;
  flex-direction: column;
  padding: 10px;
  .shareRow{
    display: flex;
    .title{
      margin-right: 10px;
      display: flex;
      align-items: center;
      min-width: 100px;
    }
    input{
      @include input-standard();
      margin-right: 10px;
    }
  }
}