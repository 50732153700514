.content{
  display: flex;
  width: calc(100%);
  justify-content: space-between;
  .left-side{
    width: calc(100% - 547px);
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
  }
  .right-side{
    width: 527px;
  }
}
.media-plan-content{
  max-height:calc(100vh - 124px);
}