@import "../mixins";
.add-flat{
  display: flex;
  flex-direction: column;
  width: 100%;
  .firstRow{
    display: flex;
    justify-content: space-between;
    .column-1{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 530px;
      margin-right: 15px;
      .titleMain{
        @include title-level-1;
      }
      .titleSmall{
        @include title-light-small;
        margin-top: 24px;
      }
      input{
        @include input-standard
      }
      textarea{
        width: 100%;
        min-height: 54px;
        resize: none;
        //text-indent: 12px;
        padding: 12px;
        font-family: GolosTextWebRegular, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.25);
        background: #F5F5F5;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 2px;
      }
      .generateButton{
        @include buttonTransparent;
        margin-top: 16px;
      }
      .noteBox{
        display: flex;
        width: 100%;
        max-width: 396px;
        padding: 8px 38px;
        background: #E3ECFF;
        border: 1px solid #7797FD;
        box-sizing: border-box;
        border-radius: 2px;
        justify-content: center;
        align-items: center;
        position: relative;
        font-family: GolosTextWebRegular, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        margin-top: 12px;
        img{
          width: 14px;
          height: 14px;
          object-fit: contain;
          position: absolute;
          top: 12px;
          left: 16px;
        }
      }
    }
    .column-2{
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 516px;
      .titleMain{
        @include title-level-1;
      }
      .fileUploadLabel{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px dashed #D9D9D9;
        box-sizing: border-box;
        border-radius: 2px;
        width: 120px;
        height: 120px;
        background: #FAFAFA;
        margin-top: 32px;
        span{
          font-family: GolosTextWebRegular, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.45);
          &:first-child{
            color: rgba(0, 0, 0, 0.85);;
          }
        }
      }
    }
    .column-3{
      display: flex;
      align-items: flex-start;
      .save-button{
        @include buttonBlue
      }
    }
  }
  .standardRow{
    display: flex;
    margin-top: 24px;
    .columns{
      display: flex;
      flex-direction: column;
      margin-right: 24px;
      .inputsRow{
        display: flex;
        align-items: center;
        span{
          margin: 0 8px;
        }
      }
      input{
        width: 192px;
        height: 32px;
        text-indent: 12px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 2px;
        &::placeholder{
          font-family: GolosTextWebRegular, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.25);
          margin-top: 4px;
        }
        &.small{
          width: 81px;
        }
        &.leftSpace{
          margin-left: 6px;
        }
      }
      .titlesSmall{
        @include title-light-small;
        margin-bottom: 8px;
      }
      .smallButtonsContainer{
        display: flex;
        .yesNoButton{
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 14.5px;
          width: fit-content;
          height: fit-content;
          font-family: GolosTextWebRegular, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19.5px;
          color: rgba(0, 0, 0, 0.45);
          border: 1px solid #D9D9D9;
          outline: none;
          border-radius: 2px;
          &.active{
            background: #E3ECFF;
            border: 1px solid #436FFD;
            box-sizing: border-box;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
            border-radius: 2px 0 0 2px;
          }
        }
      }
    }
  }
  .title{
    @include title-level-1;
    margin-top: 32px;
  }
}