.MuiFormControl-root{
  legend{
    display: none !important;
  }
}
iframe{
  display: none !important;
}
.auctionCover{
  background-color: #7e7e7e0f;
  left: 0;
  z-index: 8;
}
.preloaderSmall{
  position: absolute;
  width: calc(100% - 15px);
  height: calc(100% - 24px);
  background-color: rgba(119, 114, 114, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}
.callsTabsContainer{

}
.second{
  margin-left: 56px;
}
.white-space{
  width: 100%;
  white-space: nowrap;
}
.platformsWrapper{
  position: absolute;
  left: 41px;
  bottom: -20px;
  display: flex;
  width: calc(100% - 60px);
  justify-content: space-between;
  a{
    text-decoration: underline;
  }
}
.secondItem{
  min-height: 89px;
}
.max-35{
  max-width: 35px;
}