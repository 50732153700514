.addVasPage{
  display: flex;
  flex-direction: column;
  p.availableBudget{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    span{
      color: #000000;
      font-weight: 700;
    }
  }
  .smallTitle{
    margin-top: 24px;
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.65);
  }
  .roomsTitles{
    margin-top: 8px;
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #436FFD;
  }
  .vasGroupsRow{
    display: flex;
    margin-top: 24px;
  }
  .vasGroups{
    display: flex;
    width: 100%;
    max-width: 370px;
    flex-direction: column;
    margin-right: 25px;
    h3{
      font-family: GolosTextWebRegular, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
    table{
      width: 100%;
      th,td{

      }
      th{
        font-family: GolosTextWebRegular, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.65);
        padding: 5px 0;
      }
      td{
        width: calc(100% / 3);
        padding: 5px 0;
        padding-right: 5px;
        .titleContainer{
          display: flex;
          align-items: center;
          .icon{
            width: 24px;
            height: 24px;
            object-fit: contain;
          }
          .title{
            font-family: GolosTextWebRegular, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            margin-left: 8px;
            margin-bottom: 0;
          }
        }
        input{
          border: 1px solid #D9D9D9;
          box-sizing: border-box;
          border-radius: 2px;
          max-width: 102px;
          height: 24px;
        }
      }
    }
  }
  .buttonContainer{
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    padding-top: 20px;
  }
}