.faqContainer{
  display: flex;
  margin: 40px 0;
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  h1{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
  p{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    margin-top: 10px;
  }
  .faqContent{
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}