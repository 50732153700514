.advFlatItem{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid #F0F0F0;
  .details{
    display: flex;
    .flatImage{
      width: 52px;
      height: 52px;
      object-fit: cover;
      margin-right: 16px;
    }
    .des{
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title{
        font-family: GolosTextWebRegular, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #436FFD;
      }
      .dataContainer{
        display: flex;
        .icons{
          margin-right: 20px;
          object-fit: contain;
        }
        .vasText{
          font-size: 12px;
          margin-bottom: 0;
        }
        .item{
          font-family: GolosTextWebRegular, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color:  rgba(0, 0, 0, 0.25);
          margin-right: 22px;
          span{
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }
  .button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    height: 22px;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
  }
}