.compTagsSection{
  .MuiButtonBase-root{
    max-width: unset;
  }
}
.comp-item{
  margin-bottom: 40px;
  .itemMainTitle{
    width: 100%;
    color: white;
    background-color: #E8EFFF;
    display: flex;
    align-items: center;
    height: 30px;
    span{
      display: flex;
      width: fit-content;
      margin-left: 17px;
      color: black;
    }
  }
}
.pageComp{
  min-height: calc(100vh - 100px);
  .noData{
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
}