.stockControllerWrapper{
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 25px;
  width: calc(100% - 15px);
  height: calc(100% - 25px);
  border-radius: 5px;
  .title{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    span{
      color: #b6b6b6;
    }
  }
  input{
    width: 100%;
    height: 33px;
    border: 1px solid #D9D9D9;
    box-shadow: none;
    overflow: visible;
    text-indent: 10px;
    max-width: 515px;
    border-radius: 5px;
  }
  .buttonsContainer{
    display: flex;
    .buttons{
      height: 27px;
      background: #FFFFFF;
      border:1px solid #D9D9D9;
      border-radius: 2px 0 0 2px;
      font-family: GolosTextWebRegular, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
      margin: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0 5px;
    }
    .active{
      border: 1px solid #436FFD;
      color: #436FFD;
      background: #E8EFFF;
    }
  }
  .react-datepicker-wrapper input{
    background-image: url("../../../assets/img/img/datepickericon.jpeg");
    background-repeat: no-repeat;
    background-position: calc(100% - 5px);
    background-size: 18px;
  }
  .select{
    .MuiFormControl-root{
      min-width: unset;
      width: 100%;
      margin: 0;
    }
  }

}