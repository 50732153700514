.mainHeader{
  display: flex;
  height: 56px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: #FFFFFF;
  @media only screen and (max-width: 700px) {
    height: unset;
  }
  .common__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .menu__header{
      width: 300px;
      .menu__logo-wrap{
        opacity: 1;
      }
    }
    .nav{
      display: flex;
      @media only screen and (max-width: 700px) {
        padding-left: 10px;
      }
      li{
        a{
          font-family: 'GolosTextWebRegular',sans-serif;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          margin-left: 105px;
          text-decoration: underline;
          @media only screen and (max-width: 700px) {
            margin-left: 5px;
            font-size: 12px;
          }
          img{
            margin-right: 10px;
          }
        }
        .activeLink{
          color: #436FFD;
        }
      }
    }
  }
}