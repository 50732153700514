.advListSettings{
  display: flex;
  width: 100%;
  flex-direction: column;
  .title{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    color: rgba(0, 0, 0, 0.85);
  }
  .filterContainer{
    display: flex;
    width: 100%;
    margin-top: 25px;
    .checkbox__custom-text{
      font-family: GolosTextWebRegular, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #595959;
      margin-right: 10px;
      img{
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-left: 5px;
      }
    }
  }
  .flatsList{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 30px;
  }
}