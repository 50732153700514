.partnersContainer{
  display: flex;
  width: 100%;
  max-width: 607px;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  min-height: calc(100vh - 100px);
  h1{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
  p{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    margin-top: 10px;
  }
  .partnersTable{
    display: flex;
    width: 100%;
    padding: 20px 0;
    flex-direction: column;
    .partnerItem{
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #D9D9D9;
      .name{
        font-family: GolosTextWebRegular, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        width: 50%;
        display: flex;
        align-items: center;
      }
      .buttonsContainer{
        display: flex;
        .buttons{
          width: 150px;
          height: 32px;
          background: #FFFFFF;
          border:1px solid #D9D9D9;
          border-radius: 2px 0 0 2px;
          font-family: GolosTextWebRegular, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.45);
          margin: 0 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .active{
          border: 1px solid #436FFD;
          color: #436FFD;
          background: #E8EFFF;
        }
      }
    }
  }
  .buttonContainer{
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}