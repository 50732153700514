.leadProductsContainer{
  display: flex;
  align-items: center;
  min-height: calc(100vh - 148px);
  width: 100%;
  background:linear-gradient(238.92deg, #F8F8F8 0%, #F0F0F0 100%);
  flex-direction: column;
  h3{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 12px;
    margin-top: 80px;
  }
  p{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 22px;
    padding: 0 30px;
    text-align: center;
  }
  .addObject{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'GolosTextWebRegular', sans-serif;
    width: 320px;
    outline: none;
    height: 50px;
    background-color: #436FFD;
    color: #FFFFFF;
    transition: 0.5s;
    margin-top: 20px;
    &:hover{
      background: #7797FD;
      color: #FFFFFF;
    }
  }
}
