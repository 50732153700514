.dpButton{
  display: flex;
  width: 32px;
  height: 30px;
  font-size: 14px;
  background-color: white;
  //background-image: url("../../assets/img/sprites/sprite.svg#calendar");
  background-repeat: no-repeat;
  background-size: 13px 13px;
  background-position:center;
  white-space: nowrap;
  padding: 7px 12px;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #D9D9D9;
  border-radius: 2px;
}
.dpContainer{
  display: none;
  position: relative;
  .rdrCalendarWrapper{
    position: absolute;
    top: 10px;
    z-index: 1000;
    right: 0;
  }
}
.pickerOpen{
  display: flex;
}
.header__container{
  overflow: visible;
}
.dpContainer .rdrCalendarWrapper{
  border: 1px solid lightgrey;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 2.5px 14px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-radius: 0 !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  font-size: 15px !important;
}
