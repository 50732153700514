@mixin title-level-1{
  font-family: GolosTextWebRegular, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
@mixin title-light-small{
  font-family: GolosTextWebRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.65);
}
@mixin input-standard($maxWidth: 512px){
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  max-width: $maxWidth;
  height: 32px;
  text-indent: 12px;
  &::placeholder{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.25);
  }
}
@mixin buttonBlue {
  display: flex;
  width: fit-content;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  height: 32px;
  padding: 0 16px;
  background: #436FFD;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  font-family: GolosTextWebRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
}
@mixin buttonBlueTransparent {
  display: flex;
  width: fit-content;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  height: 32px;
  padding: 0 16px;
  font-family: GolosTextWebRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color:  #436FFD;
  background: #E8EFFF;
  border: 1px solid #436FFD;
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 2px 0 0 2px;
}
@mixin buttonTransparent{
  display: flex;
  width: fit-content;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  padding: 0 16px;
  font-family: GolosTextWebRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
@mixin popUpContainer{
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;

  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: 0.7s;
  z-index: 999;
  &.active{
    right: 0;
    background: #00000082;
  }
}
@mixin flexRow{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
@mixin flexColumn{
  display: flex;
  flex-direction: column;
  width: 100%;
}
@mixin muiDatePicker{
  .MuiFormControl-root{
    width: 100% !important;
  }
  .MuiOutlinedInput-input{
    padding: 3px 14px;
  }
  .MuiOutlinedInput-notchedOutline{
    top:-3px;
  }
  .MuiSvgIcon-root{
    font-size: 1.3rem;
  }
}
@mixin select-mui{
  .MuiFormControl-root{
    margin: 0;
    width: 100%;
    min-width: unset;
  }
}