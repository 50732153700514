@import "../variables";
@import "../mixins";
.media-plan-counter{
  background-color: #ffffff;
  width: 527px;
  padding: 10px 0;
  margin-top: 20px;
  border-radius: 3px;
  border: 1px solid #000000;
  .output__tabs{
    padding: 0 10px;
  }
  a{
    color: inherit;
  }
  .counter-item{
    display: flex;
    flex-direction: column;
    padding-top: 13px;
    height: 300px;
    overflow: auto;
    background-color: #ffffff;

    .content{
      display: none;
      flex-direction: column;
      background-color: #ffffff;
      border-top: 1px solid #000000;
      padding:10px;
      img{
        width: 130px;
        object-fit: contain;
        margin-bottom: 12px;
      }
      p{
        @include title-light-small;
        span{
          font-weight: 600;
        }
      }
    }
  }
}