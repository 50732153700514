@import "../mixins";
.stockItem{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  .titleSide{
    display: flex;
    width: 100%;
    max-width: 400px;
    margin-right: 20px;
    h2{
      font-family: GolosTextWebRegular, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .mainSide{
    display: flex;
    width: 100%;
    max-width: calc(100% - 420px);
    flex-direction: column;
    .titleContainer{
      display: flex;
      width: 100%;
      justify-content: space-between;
      h3.title{
        @include title-level-1;
        width: fit-content;
        max-width: 536px;
      }
      .buttonsContainer{
        display: flex;
        button{
          display: flex;
          width: 24px;
          height: 24px;
          outline: none;
          border: 1px solid #D9D9D9;
          box-sizing: border-box;
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
          border-radius: 2px;
          justify-content: center;
          align-items: center;
          margin-left: 8px;
          img{
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    p.text{
      margin-top: 10px;
      margin-bottom: 0;
      font-family: GolosTextWebRegular, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      max-width: 768px;
    }
    .section{
      display: flex;
      &.sectionRow{
        margin-top: 25px;
      }
        .columnLogo{
          display: flex;
          flex-direction: column;
          &.first{
            width: 150px;
          }
            .smallTitle{
              @include title-light-small;
              margin-bottom: 12px;
            }
            .logo{
              display: flex;
              width: 40px;
              height: 40px;
              justify-content: center;
              align-items: center;
              border: 1px solid #D9D9D9;
              box-sizing: border-box;
              border-radius: 2px;
              overflow: hidden;
              img{
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .developerName{
              font-family: GolosTextWebRegular, sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: rgba(0, 0, 0, 0.85);
              margin-left: 8px;
            }
            .objects{
              display: flex;
              flex-wrap: wrap;
              max-width: 590px;
              padding-right: 10px;
            }
        }

    }
  }
}