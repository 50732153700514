.smartLotItem{
  display: flex;
  width: 100%;
  padding: 15px;
  .imageContainer{
    width: 220px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    .imageData{
      font-size: 12px;
      text-align: center;
    }
    .sm-image{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  table{
    width: 100%;
    border-collapse: collapse;
    th, td{
      border: 1px solid rgba(59, 59, 59, 0.31);
      text-align: center;
      vertical-align: center;
    }
    th{
      background-color: rgba(59, 59, 59, 0.12);
      height: 35px;
    }
    td{
      p{
        margin-bottom: 0;
      }
    }
  }
}