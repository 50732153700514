@import "../mixins";
.stockPage-deletePopup-content{
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  p{
    @include title-level-1;
    text-align: center;
  }
  .buttonsContainer{
    display: flex;
    justify-content: space-between;
    button{
      margin: 0 10px;
      @include buttonTransparent;
      &:last-child{
        @include  buttonBlue;
      }
    }
  }
}