@import '../mixins';
.addStock{
  @include popUpContainer;
  .popUpContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 708px;
    background: #FFFFFF;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    padding: 24px;
    max-height: calc(100vh - 40px);
    overflow-x: auto;
    .flexRow{
      @include flexRow;
      .title{
        @include title-level-1;
        margin-bottom: 0;
      }
      .buttonClose{
        background: none;
        border: none;
        padding: 0;
      }
    }
    .flex-column{
      @include flexColumn;
      margin-top: 24px;
      .title{
        @include title-light-small;
      }
      input:not(.MuiOutlinedInput-input){
          @include input-standard(100%);
          margin-top: 8px;
      }
      textarea{
        @include input-standard(100%);
        min-height: 150px;
        margin-top: 8px;
      }
    }
    .twiceGrid{
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 24px;
    }
    .date-picker{
      @include muiDatePicker;
      display: flex;
      margin-top: 8px;
      width: 100%;
    }
    .mui-switch-on-off{
      .MuiSwitch-root{
        overflow: unset!important;
      }
    }
    .select-mui{
      @include select-mui;
      margin-top: 8px;
      p{
        margin-top: 8px;
        font-size: 11px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .buttonsContainer{
      display: flex;
      width: 100%;
      justify-content:flex-end;
      margin-top: 24px;
      .buttonLight{
        @include buttonTransparent;
      }
      .buttonActive{
        @include buttonBlue;
        margin-left: 15px;
      }
    }
  }
}