.advInfoHeader{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .info{
    display: flex;
    flex-direction: column;
    .title{
      font-family: GolosTextWebRegular, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 120%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #828282;
    }
    .data{
      font-family: GolosTextWebRegular, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      span{
        color: #828282;
      }
    }
  }
  .settings{
    button{
      width: 32px;
      height: 32px;
      display: flex;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      justify-content: center;
      align-items: center;
      outline: none;
    }
  }
  .MuiFormControl-root{
    margin: 0;
  }
}