.chartContainer{
  margin: auto;
  width: 100%;
}
.chartTabsContainer{
  width: fit-content;
}
.graph{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 550px;
  flex-direction: column;
  margin: auto;
  .item{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 3px 0;
    position: relative;
    &:nth-child(1){
      background-color: #58d6d8;
      div{
        border-top-color: #58d6d8;
      }
    }
    &:nth-child(2){
      background-color: #fa8080;
      width: calc(100% - 111px);
      div{
        border-top-color: #fa8080;
      }
      .text-left{
        left: -253px;
      }
      .text-right{
        right: -100px;
      }
    }
    &:nth-child(3){
      background-color: #fdcc78;
      width: calc(100% - 219px);
      div{
        border-top-color: #fdcc78;
      }
      .text-left{
        left: -305px;
      }
      .text-right{
        right: -152px;
      }
    }
    &:nth-child(4){
      background-color: #636aff;
      width: calc(100% - 329px);
      div{
        border-top-color: #636aff;
      }
      .text-left{
        left: -359px;
        white-space: nowrap;
      }
      .text-right{
        right: -204px;
      }
    }
    &:nth-child(5){
      background-color: orange;
      width: calc(100% - 436px);
      div{
        border-top-color: orange;
      }
      .text-left{
        left: -412px;
        white-space: nowrap;
      }
      .text-right{
        right: -255px;
      }
    }
    &:nth-child(6){
      background-color: #6bdc09;
      width: calc(100% - 551px);
      span{
        z-index: 1;
        position: relative;
        top: -5px;
      }
      div{
        border-top-color: #6bdc09;
      }
      .text-left{
        left: -469px;
        white-space: nowrap;
      }
      .text-right{
        right: -307px;
      }
    }
    .rectLeft{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 36px;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      position: absolute;
      left: -36px;
      top: -36px;
      transform: rotate(-135deg);
    }
    .rectRight{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 36px;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      position: absolute;
      right: -36px;
      top: -36px;
      transform: rotate(-225deg);
    }
    .text-left{
      width: fit-content;
      position: absolute;
      left: -200px;
      padding: 0;
      margin: 0;
      color: #000000;
      text-align: center;
    }
    .text-right{
      width: fit-content;
      position: absolute;
      right: -70px;
      padding: 0;
      margin: 0;
      color: #000000;

    }
  }
}