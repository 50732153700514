.settings-wrap{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10px;
}
.settingsButton{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 6px 9px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid #D9D9D9;
  img{
    margin-right: 8px;
  }
}
.radioContainer{
  .MuiTypography-root{
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
}
.chartTitle{
  font-family: GolosTextWebRegular, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
}
.m-200{
  margin-bottom: 200px;
}
.bidStoryTitle{
  font-family: GolosTextWebRegular, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0;
}

.auctionState{
  display: grid;
  grid-template-columns: repeat(11,1fr);
  grid-gap: 1px;
  background-color: #F0F0F0;
  border: 1px solid #F0F0F0;
  margin-top: 24px;
  .item{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background-color: #ffffff;
    font-family: GolosTextWebRegular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    &:nth-child(1),&:nth-child(12),,&:nth-child(23),{
      font-weight: 600;
      background: #FAFAFA;
      mix-blend-mode: multiply;
      box-shadow: inset -1px 0px 0px #F0F0F0, inset 1px 0px 0px #F0F0F0;
    }
  }
}