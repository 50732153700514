@import "../mixins";
.stock-popup{
  .MuiTypography-root{
    font-size: 12px;
    font-weight: bold;
  }
  .buttonsContainer{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    .blue{
      @include buttonBlue;
      margin-left: 10px;
    }
    .gray{
      @include buttonTransparent;
    }
  }
}