.add-object-container{
  display: flex;
  width: 100%;
  align-items: center;
  .add-object{
    display: flex;
    width: 100%;
    max-width: 1100px;
    flex-direction: column;
    .buttonContainer{
      padding: 15px;
      .save{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        button{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 150px;
          height: 32px;
          background: #436FFD;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 2px;
          outline: none;
          border: none;
          color: #FFFFFF;
        }
      }
    }
    .buttonContainer{

    }
    .priceTableContainer{
      display: flex;
      width: 100%;
      flex-direction: column;
      h3{
        font-family: GolosTextWebRegular,sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.65);
        margin-top: 24px;
      }
      .priceTable{
        width: 500px;
        border-collapse: collapse;
        td, th{
          border: 1px solid lightgrey;
          padding: 7px 15px;
        }
      }
    }
  }
}
.column2{
  display: none !important;
}
.columnsContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  .columns{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 15px;
    .mainSectionTitle{
      font-family: GolosTextWebRegular,sans-serif;
      font-weight: 900;
      font-size: 19px;
      line-height: 25px;
      color: black;
      margin-top: 20px;
      margin-bottom: 0;
    }
    .columnRow{
      display: flex;
      width: 100%;
      justify-content: space-between;
      .smallColumn{
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-right: 20px;
      }
    }
    .sectionTitle{
      display: flex;
      align-items: center;
      font-family: GolosTextWebRegular,sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #6a6363;
      margin-top: 24px;
    }
    .inpContainer{
      width: 100%;
      margin-top: 8px;
      .add-obj-inp{
        width: 100%;
        height: 37px;
        border: 1px solid #D9D9D9;
        box-shadow: none;
        overflow: visible;
        text-indent: 10px;
        max-width: 515px;
        &:focus{
          border: 1px solid #436FFD;
        }
      }
      .dataPickerContainer{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 41px;

        .pickerLabel{
          margin-right: 15px;
          font-family: 'GolosTextWebRegular', sans-serif;
          color: #1a1e21;
        }
        .css-4l7j15{
          box-shadow: 1px 1px 6px 1px lightgrey;
        }
        .MuiOutlinedInput-input{
          height: 23px;
          padding: 5px 14px;
          position: relative;
          z-index: 100;
        }
        .MuiOutlinedInput-notchedOutline{
          z-index: 1;
          background-color: white;
        }
      }
      .monthDatePicker{
        .MuiFormControl-root{
          width: calc(100% - 139px);
        }
        .MuiInputAdornment-root{
          z-index: 100;
        }
      }
      .timePickerContainer{
        height: 40px;
        .MuiOutlinedInput-input{
          padding: 5px 10px;
        }
      }
    }
    .fileContainer{
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      justify-content: space-between;
      max-width: 515px;
      .fileName{
        width: calc(100% - 365px);
        margin-right: 10px;
        margin-bottom: 0;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
      .fileTitle{
        font-family: 'GolosTextWebRegular', sans-serif;
        font-size: 14px;
        color: #1a1e21;
        margin-right: 15px;
        width: 190px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
      .fileLabel{
        width: 157px;
        height: 32px;
        background: #436FFD;
        border: 1px solid #436FFD;
        box-sizing: border-box;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        img{
          margin-right: 10px;
        }
      }
      .fileInp{
        width: 157px;
        height: 32px;
        background: #436FFD;
        border: 1px solid #436FFD;
        box-sizing: border-box;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        display: flex;
        outline: none;
        overflow: hidden;
        justify-content: center;
        align-items: center;
      }
    }
    .radioClassesContainer{
      display: flex;
      margin-top: 10px;
      height: 67px;
      padding-left: 5px;
      height: 30px;
      .MuiFormGroup-root{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
      .MuiSvgIcon-root{
        background: #ffffff;
        border-radius: 50%;
      }
      .labelContainer{
        display: flex;
        align-items: center;
        .product__desc-icon{
          margin-right: 3px;
        }
        .labelText{
          margin-bottom: 0;
          font-size: 12px;
          font-family: GolosTextWebRegular, sans-serif;

        }
      }
    }
    .select{
      display: flex;
      margin-top: 13px;
      .MuiFormControl-root{
        margin-left: 0;
        margin-top: 0;
        width: 100%;
        min-width: unset;
      }
      .MuiSelect-select{
        min-height: 26.5px;
        background: #fff;
        height: 100%;
        align-items: center;
        display: flex;
        position: relative;
        top: -3px;
        padding: 2.5px 14px;

      }
      .MuiSvgIcon-root{
        fill: transparent;
        background-image: url("../../assets/img/img/downvector.png");
        background-repeat: no-repeat;
        background-position: center;
      }
      .MuiSelect-iconOpen{
        background-image: url("../../assets/img/img/downvectorblue.png");
      }
      .product__desc-icon{
        position: static !important;
        @media screen and (max-width: 1024px){
          position: static !important;
        }
      }
    }
    .checkboxesTypes{
      display: flex;
      flex-wrap: wrap;
      .MuiTypography-root{
        font-size: 14px;
      }
    }
  }
}