.visibility-table{
  th,td{
    border: 1px solid lightgrey;
    border-collapse: collapse;
    padding: 5px;
    text-align: center;
  }
  th{
    text-align: center;
    background-color: lightgrey;
    color: #ffffff;
  }
  td:first-child{
    width: 40%;
  }
}
.visibility-table2{
  th,td{
    border: 1px solid lightgrey;
    border-collapse: collapse;
    padding: 5px;
    text-align: center;
  }
  th{
    text-align: center;
    background-color: lightgrey;
    color: #ffffff;
  }
  td:first-child{
    width: 40%;
  }
}