.paginationjs-pages ul {
  display: flex;
  align-items: center;
}

.paginationjs-page a, .J-paginationjs-previous a, .J-paginationjs-next a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: #fff;
  color: #595959;
  transition: 0.3s;
}

.paginationjs-page.active a {
  color: #436FFD;
  border-color: #436FFD;
}

.apartment__table-price {
  text-decoration: line-through;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 5px 14px !important;
}
.filter__modal{
  min-width:320px;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root{
  border: 1px solid #D9D9D9 !important;
}
.apartment__plan{
  [data-rmiz-wrap="visible"], [data-rmiz-wrap="hidden"]{
    width: 100%;
    height: 100%;
    picture{
      width: 100%;
      height: 100%;
    }
  }
}
.calls_audio{
  position: relative;
  //width: 210px;
}

[data-rmiz-modal-content]:before {
  content: 'x';
  position: absolute;
  top: 0;
  right: -10px;
  width: 3px;
  height: 8px;
  color: red;
  font-size: 4px;
  display: flex;
  align-items: center;
}
.flat_building{
  max-width: 100px ;
  overflow: hidden;
}
.output__item, .flatsPopupContainer{
  .link__list-col{
    width: 100%;
    max-width: unset;
  }
  .link__list{
    width: 100%;
    max-width: unset;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 42px;
    .link__list-item{
      display: flex;
      width: 100%;
      justify-content: flex-start;
      .link__list-action{
        width: 100%;
        span{
          margin-right: 15px;
        }
      }
    }
  }
}
.price_flat{
  white-space: nowrap;
}
.css-1v4ccyo{
  max-height: 35px;
}
.playerHeader{
  min-width: 318px;
  padding-left: 0;
}
.menu{
  &:hover{
    .logoutContainer{
      margin-top: 10px;
      padding-top: 0;
      padding-bottom: 12px;
      height: unset;
    }
  }
}
.logoutContainer{
  width: 100%;
  height: 0;
  display: flex;
  justify-content: center;
  a{
    color: black;
  }
}
.graph-sales{
  margin-bottom: 100px;
}
.menu-space{
  margin-top: 20px;
}
.pl-item{
  min-width: 200px;
}
[data-rmiz-modal-content]{
  display: flex;
  align-items: center;
}
.add-object-button{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #436FFD;
  height: 36px;
  width: 320px;
  transition: 0.5s;
  margin-left: 15px;
  font-family: GolosTextWebRegular,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
   &:hover{
    background: #7797FD;
    color: #FFFFFF;
  }
}
.disabledButton{
  background: #F5F5F5 !important;
  border: 1px solid #D9D9D9 !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  color: rgba(0, 0, 0, 0.25) !important;
  &:hover{
    background: #F5F5F5 !important;
    border: 1px solid #D9D9D9 !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
.home-header-last-button{
  border-radius: 0 2px 2px 0;
  border-right: 1px solid #D9D9D9;
}
.calls-exchange-page{
  background: linear-gradient(238.92deg, #F8F8F8 0%, #F0F0F0 100%);
  min-height: calc(100vh - 171px);
}
.ml{
  margin-left: 10px;
}
.reloadIcon{
  margin-left: 10px;
  width: 15px;
  object-fit: contain;
  cursor: pointer;
}
.numberContainer{
  display: flex;
}
.standardBlueButton{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 32px;
  background: #436FFD;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  color: white;
  margin-top: 7px;
  cursor: pointer;
}
.standardGrayButton{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 32px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  border: 1px solid #D9D9D9;
  margin-top: 7px;
  cursor: pointer;
}
.callsFilter{
  margin-bottom: 20px;
  width: 100%;
  .common__container{
    padding: 0;
  }
}
.priceChartContainer{
  .output__tabs{
    justify-content: flex-start;
  }
  .output__tabs-item{
    flex: unset;
    margin-right: 20px;
  }
}
.avgChart{
  max-width: 100%;
}
//.vasChart{
//  .series.bar{
//
//    rect{
//      height: 34px;
//      position: relative;
//      top: 10px;
//    }
//  }
//  .Series{
//    transform: translate(0px, calc(100% - 193px));
//  }
//}
.classifiedPage{
  min-height: calc(100vh - 100px);
}
.bigButton{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'GolosTextWebRegular', sans-serif;
  width: 320px !important;
  outline: none;
  height: 50px !important;
  background-color: #436FFD;
  color: #FFFFFF;
  transition: 0.5s;
  margin-top: 20px;
}
.vasChartSection{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 248px;
  .ReactChart{
    svg{
      width: calc(100% + 20px) !important;
    }
  }
}
.standardHeader{
}
.form__datepicker-wrap{
  width: unset;
}
.complexDetails{
  .product__desc-text--inside{
    margin: 12px 0;
  }
}
.flatsFilter{
  padding: 23px 10px 21px 10px;
}
.noData{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.menuCover{
  display: flex;
  flex: 1 1;
}
.showMore{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 47px;
  font-family: GolosTextWebRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  height: 32px;
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
}
.vasTitleContainer{
  padding-left: 15px;
  display: flex;
  align-items: center;
}
.my-3{
  margin: 0 5px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.advHeader{
  .output__tabs-item{
    margin-right: 30px;
    border-bottom: unset;
  }
  .MuiFormControl-root{
    margin: 0;
    margin-right: 20px;
    margin-bottom: -10px;
  }
}
.nastoTitleOfferList{
  font-family: GolosTextWebRegular, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
.filterButton{
  height: 40px;
  bottom: 3px;
  position: relative;
}
.flatsCount{
  font-size: 14px;
}
.flatsStockIcon{
  display: flex;
  height: 20px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: #FA541C;
  border-radius: 11px;
  width: 100px !important;
  white-space: nowrap;
  padding: 0 10px;
  font-size: 10px;
  position: relative;
  left: -12px;
}
.align-center{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 13px;
  width: 400px !important;
  max-width: unset;
  min-width: 102px;
  .stockIcon{
  }
}
.link__list li p{
  font-size: 12px;
}
.link__list{
  max-height: 60vh;
  overflow: auto;
}
.nonStocks{
  display: grid;
  align-content: center;
  justify-items: center;
  width: 100%;
  grid-column: 1/4;
  min-height: 200px;
  h2{
    font-size: 18px;
  }
  a{
    text-align: center;
    margin-top: 15px;
  }
}
.selectedFlatsDesc{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  p{
    margin-bottom: 0;
    margin-right: 5px;
  }
  span{
    font-size: 12px;
    text-decoration: underline;
    margin-top: 3px;
    cursor: pointer;
    color: #436FFD;
  }
}
.stocksLists{
  grid-template-columns: repeat(2,1fr) !important;
}
.mal-10{
  margin-left: 50px !important;
}