.leadPopup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 99999;
  top: 0;
  left: 0;
  .container{
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    max-width: 464px;
    background-color: #FFFFFF;
    position: relative;
    max-height: 90vh;
    overflow: auto;
    .title{
      font-family: GolosTextWebRegular,sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
    }
    .subTitle{
      font-family: GolosTextWebRegular,sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.65);
      margin-top: 24px;
    }
    .checksContainer{
      display: flex;
      margin-top: 8px;
      .MuiFormGroup-root{
        flex-direction: row;
      }
      textarea{
        width: 100%;
      }
    }
    .fileUploadContainer{
      .fileContainer{
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        justify-content: space-between;
        max-width: 515px;
        .fileTitle{
          font-family: 'GolosTextWebRegular', sans-serif;
          font-size: 14px;
          color: #1a1e21;
          margin-right: 15px;
          width: 200px;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          word-break: break-all;
          padding-right: 10px;
        }
        .fileLabel{
          width: 157px;
          height: 32px;
          background: #436FFD;
          border: 1px solid #436FFD;
          box-sizing: border-box;
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          cursor: pointer;
          img{
            margin-right: 10px;
          }
        }
        .fileInp{
          width: 157px;
          height: 32px;
          background: #436FFD;
          border: 1px solid #436FFD;
          box-sizing: border-box;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 2px;
          display: flex;
          outline: none;
          overflow: hidden;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .tarifs{
      display: flex;
      align-items: center;
      font-family: GolosTextWebRegular,sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.65);
      margin-top: 24px;
    }
    .priceSection{
      display: flex;
      width: 100%;
      padding: 10px;
      border: 1px solid #436FFD;
      justify-content: space-between;
      margin-top: 8px;
      .text{
        font-family: GolosTextWebRegular,sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        max-width: calc(100% - 76px);
      }
      .price{
        width: 70px;
        font-family: GolosTextWebRegular,sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .select{
      margin-top: 4px;
      width: 100%;
      .css-1ax5w3t-MuiFormControl-root{
        margin-left: 0 !important;
      }
    }
    .save{
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 24px;
      button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 106px;
        height: 32px;
        background: #436FFD;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        outline: none;
        border: none;
        color: #FFFFFF;
      }
    }
    .close{
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 23px;
      line-height: 17px;
      cursor: pointer;
    }
  }
}