.statusContainer{
  display: flex;
  align-items: center;
  .common__hint{
    &:hover{
      .common__hint-desc{
        display: block;
      }
    }
    .common__hint-desc{
      position: absolute;
      width: 270px;
      font-size: 10px;
      line-height: 12px;
      color: #fff;
      padding: 7px;
      border-radius: 4px;
      display: none;
      text-transform: initial;
      top: -39px;
      z-index: 1;
      border: 1px solid lightgrey;
      background: #f6f6f6;
      right: -271px;
      .standardGrayButton{
        color: grey;
      }
    }
    .barIcon{
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }
}
